import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import { meT } from "dora-contracts/web-booking/me";
import * as t from "io-ts";
import * as tPromise from "io-ts-promise";
import { unwrap } from "../../ducks/helpers";
import { webBookingMeT as userT } from "dora-contracts";

// type Me = t.TypeOf<typeof meT>

type User = t.TypeOf<typeof userT>;

const prefix = "auth";

type AuthState =
  | { type: "UNINITIALIZED" }
  | { type: "UNAUTHENTICATED" }
  | { type: "AUTHENTICATED"; me: User }
  | { type: "AUTH_ERROR" };

type State = { authState: AuthState };

const initialState: State = { authState: { type: "UNINITIALIZED" } };

class BadRequestError<T> extends Error {
  public code: T;

  constructor(msg: string, code: T) {
    super(msg);
    this.code = code;
  }
}

export const initialize = createAsyncThunk(
  `${prefix}/initialize`,
  async (): Promise<AuthState> => {
    try {
      const response = await fetch("/web-booking-api/auth/me");
      switch (response.status) {
        case 200: {
          const me = await response.json().then(tPromise.decode(userT));
          return { type: "AUTHENTICATED", me };
        }
        case 401: {
          return { type: "UNAUTHENTICATED" };
        }
      }
    } catch {}
    return { type: "AUTH_ERROR" };
  }
);

const doActivateAccount = createAsyncThunk(
  `${prefix}/activate-account`,
  async (data: { email: string; activationCode: string; password: string }) => {
    const result = await fetch("/web-booking-api/auth/activate", {
      method: "post",
      body: JSON.stringify(data),
      headers: { "Content-Type": "application/json" },
    });
    switch (result.status) {
      case 200:
        return;
      case 400: {
        const code = (await result.json()).code as
          | "ERR_ACTIVATION_CODE_DOESNT_EXIST"
          | "ERR_ACTIVATION_CODE_EXPIRED"
          | "ERR_INVALID_ACTIVATION_CODE";
        throw new BadRequestError("Error activating user", code);
      }
      default:
        throw new Error("Error from backend");
    }
  }
);

const doLogin = createAsyncThunk(
  `${prefix}/login`,
  async (data: { email: string; password: string }) => {
    const result = await fetch("/web-booking-api/auth/login", {
      method: "post",
      body: JSON.stringify(data),
      headers: { "Content-Type": "application/json" },
    });
    switch (result.status) {
      case 200:
        return result.json().then(tPromise.decode(userT));
      case 401:
        throw new Error("Invalid credentials");
    }
    throw new Error("Invalid credentials");
  }
);

export const login = unwrap(doLogin);
export const activateAccount = unwrap(doActivateAccount);

const slice = createSlice({
  initialState,
  name: prefix,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(initialize.fulfilled, (state, action) => {
        state.authState = action.payload;
      })
      .addCase(doLogin.fulfilled, (state, action) => {
        state.authState = { type: "AUTHENTICATED", me: action.payload };
      });
  },
});

export default slice.reducer;
