import { Suspense, useEffect } from "react";
import "./App.css";
import "./Imports.scss";
import { initialize } from "./ducks/org";
import { selectOrg } from "./ducks/org/selectors";
import { useAppDispatch, useAppSelector } from "./redux-store";
import { RouterProvider } from "react-router-dom";
import { OrgContext } from "./routes/OrgContext";
import createRouter from "./routes/createRouter";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useSelector } from "react-redux";
import { selectCurrentLanguage } from "./ducks/app/user-settings/selectors";
import { changeLanguage } from "./i18n";
import * as featureActions from "./ducks/features";

const router = createRouter();

const LanguageSwitcher = () => {
  const selectedLanguage = useSelector(selectCurrentLanguage);
  useEffect(() => {
    changeLanguage(selectedLanguage);
  }, [selectedLanguage]);
  return null;
};

function App() {
  const state = useAppSelector(selectOrg);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(initialize());
    dispatch(featureActions.initialize());
  }, [dispatch]);

  return !state ? null : (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <LanguageSwitcher />
      <Suspense fallback={<div>Loading...</div>}>
        <OrgContext.Provider value={state}>
          <RouterProvider router={router} />
        </OrgContext.Provider>
      </Suspense>
    </LocalizationProvider>
  );
}

export default App;
