import { selectNotifications } from "../ducks/app/notifications/selectors";
import { useAppSelector } from "../redux-store";
import classnames from "classnames";
import "./Notifications.css";

const Notifications = () => {
  const notifications = useAppSelector(selectNotifications);
  return notifications.length === 0 ? null : (
    <div className="notifications">
      {notifications.map((n) => (
        <div
          role="status"
          aria-live="polite"
          key={n.id}
          className={classnames("notifications__box", {
            "notifications__box--error": n.level === "error",
          })}
        >
          {n.message}
        </div>
      ))}
    </div>
  );
};

export default Notifications;
