import { useContext, createContext, useEffect } from "react";

export const OrgContext = createContext<{ name: string } | undefined>(
  undefined
);

export const useOrg = () => {
  const result = useContext(OrgContext);
  if (!result) {
    throw new Error("Not in an Org Context");
  }
  return result;
};
