import { RootState } from "../../../redux-store";

// export const selectDarkMode = (state: RootState) =>
//     state.app.userSettings.darkMode;
//
// export const selectDisableTouchDevice = (state: RootState) =>
//    state.app.userSettings.disableTouchDevice;

export const selectCurrentLanguage = (state: RootState) =>
  state.app.userSettings.language;
