import {
  OrderWizardCargoPieceRedux,
  OrderWizardCargoPiece,
} from "../shared/cargo-pieces";

type OrderWizardStopBase = {
  lm: string;
  time: string;
  weight: string;
  colli: string;
  length: string;
  width: string;
  height: string;
  cubicMeters: string;
  place: Address | null;
  ref: string;
  contact: string;
  phone: string;
  fix: boolean;
  temperature: string;
  tag: string;

  // for local state only
  tagManuallyModified: boolean;
};

export type OrderWizardStopRedux = OrderWizardStopBase & {
  date: string;
  cargoPieces: OrderWizardCargoPieceRedux[];
};

export type OrderWizardStop = OrderWizardStopBase & {
  date: Date | null;
  cargoPieces: OrderWizardCargoPiece[];
};

const assertNotEmpty = <T extends string>(x: T | "") => {
  if (!x) {
    throw new Error("Invalid empty input");
  }
  return x;
};

const convertCargoPieceToReduxCompatible = (
  x: OrderWizardCargoPiece
): OrderWizardCargoPieceRedux => ({
  quantity: +x.quantity,
  unit: assertNotEmpty(x.unit),
});

export const orderStopToReduxCompatible = (
  o: OrderWizardStop
): OrderWizardStopRedux => {
  return {
    ...o,
    cargoPieces: o.cargoPieces.map(convertCargoPieceToReduxCompatible),
    date: (o.date as Date).toISOString(),
  };
};

export type Address = {
  hereMapsId?: string;
  placeName: string;
  address: string;
  postcode: string;
  city: string;
  countryCode: string;
  coords: { lon: number; lat: number };
};
