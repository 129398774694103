import { Outlet } from "react-router-dom";
import "../App.css";
import Header from "../components/Header";
import { useOrg } from "./OrgContext";
import Notifications from "./Notifications";

const Root = () => {
  const org = useOrg();
  return (
    <div className="layout">
      <Header orgName={org.name} />
      <Outlet />
      <Notifications />
    </div>
  );
};

export default Root;
