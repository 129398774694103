import { DateTime } from "luxon";

export const getBusinessDayFromNowDate = (options?: {
  addExtraDay: boolean;
}): Date => {
  const now = DateTime.now();
  const todayAtNoon = DateTime.now().set({
    hour: 12,
    minute: 0,
    second: 0,
    millisecond: 0,
  });
  let businessDaysFromToday = todayAtNoon > now ? 1 : 2;
  let result = DateTime.now();
  while (businessDaysFromToday) {
    if (result.weekday === 6 || result.weekday === 7) {
      result = result.plus({ day: 1 });
      continue;
    }
    result = result.plus({ day: 1 });
    businessDaysFromToday--;
  }
  if (options?.addExtraDay) {
    result = result.plus({ day: 1 });
  }
  return result.toJSDate();
};
