import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunkAction } from "../../redux-store";
import mapValues from "lodash/mapValues";

const prefix = "features";

type Feature = { enabled: boolean };
type State = Record<string, Feature>;

const initialState: State = {};

export const initialize = (): AppThunkAction => async (dispatch) => {
  const r = await fetch("/web-booking-api/features");
  if (!r.ok) {
    throw new Error("Error loading features");
  }
  const data = await r.json();
  if (typeof data !== "object") return;
  dispatch(
    slice.actions.setFeatures(
      mapValues(data, (v) => ({
        enabled: !!v.enabled,
      }))
    )
  );
};

const slice = createSlice({
  initialState,
  name: prefix,
  reducers: {
    setFeatures: (_, action: PayloadAction<State>) => {
      return action.payload;
    },
  },
});

export default slice.reducer;
