import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { Settings } from "luxon";
import { initReactI18next } from "react-i18next";
import resourcesToBackend from "i18next-resources-to-backend";

export const Languages = {
  da: "Dansk",
  en: "English",
} as const;

export type LanguageType = keyof typeof Languages;
export const supportedLanguages: LanguageType[] = Object.keys(
  Languages
) as LanguageType[];

const setDateLanguage = (language: string) => {
  // Luxon - if used
  Settings.defaultLocale = i18n.language;
};

i18n
  //.use(HttpBackend)
  .use(
    resourcesToBackend(
      (language: string, namespace: string) =>
        import(`./translations/${language}/${namespace}.json`)
    )
  )
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(
    {
      lng: "da",

      fallbackLng: "da",
      nsSeparator: ":",
      defaultNS: undefined,
      interpolation: {
        escapeValue: false, // not needed for react as it does escape per default to prevent xss!
      },
      // backend: {
      //   loadPath: "/web-booking-api/translations/{{lng}}/{{ns}}",
      //   requestOptions: {
      //     mode: "cors",
      //     credentials: "same-origin",
      //     cache: "default",
      //   },
      // },
    },
    (err, t) => {
      setDateLanguage(i18n.language);
      document.documentElement.lang = i18n.language;
    }
  );

export const { t } = i18n;

export const changeLanguage = async (language: LanguageType) => {
  await i18n.changeLanguage(language);
  setDateLanguage(language);
  document.documentElement.lang = i18n.language;
};
