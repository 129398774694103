import { AnyAction, configureStore, ThunkAction } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import reducer from "./ducks";

export const createStore = () => configureStore({ reducer });

export type AppThunkAction<T = void> = ThunkAction<
  T,
  RootState,
  any,
  AnyAction
>;
export type RootState = ReturnType<typeof reducer>;
export const store = createStore();
export type RootStore = typeof store;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: typeof useSelector = (selector) =>
  useSelector(selector);
export type Action = Parameters<AppDispatch>[0];
export type ActionCreator<T extends any[]> = (...args: T) => Action;
export type Selector<T> = (state: RootState) => T;
