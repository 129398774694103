import FileUploadIcon from "@mui/icons-material/FileUpload";
import { IconButton } from "./Button";
import "./FileUploadContainer.scss";

type FileUploadContainerProps = {
  text1: string;
  text2: string;
};

const FileUploadContainer = (props: FileUploadContainerProps) => {
  return (
    <IconButton
      className="file-upload-container"
      onClick={(e) => e.preventDefault()}
    >
      <FileUploadIcon></FileUploadIcon>
      <div>
        {props.text1}
        <br />
        {props.text2}
      </div>
      <div>Find dokument</div>
    </IconButton>
  );
};

export default FileUploadContainer;
