import * as React from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";
import { Drawer } from "@mui/material";

import "./Header.css";
import logo from "../assets/logo.svg?react";
import { Menu } from "../routes/createRouter";
import Button from "./Button";

const Header = ({ orgName }: { orgName: string }) => {
  const [isHeaderMobileOpen, setHeaderMobileOpen] = React.useState(false);

  const toggleHeaderMobileOpen = () => {
    setHeaderMobileOpen((x) => !x);
  };
  return (
    <div>
      <Drawer
        onClose={toggleHeaderMobileOpen}
        open={isHeaderMobileOpen}
        variant="temporary"
      >
        <Menu onItemSelected={toggleHeaderMobileOpen} />
      </Drawer>
      <div className="header">
        <Button
          variant="ghost"
          className="header__menu-toggle-button"
          onClick={toggleHeaderMobileOpen}
        >
          <MenuIcon className="header__menu-toggle-icon d-sm-none" />
        </Button>
        <Link to="/">
          <img className="header__logo" src={logo as any} alt="Logo" />
        </Link>
        <div className="header__org d-md-block">{orgName} Web booking</div>
      </div>
    </div>
  );
};

export default Header;
