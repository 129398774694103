import { createAsyncThunk } from "@reduxjs/toolkit";
// import { reportError, formatError } from "../helpers/error-helper";
import * as notificationActions from "./app/notifications";
import { createAppAsyncThunk } from "../redux-helpers";
import { AppDispatch } from "../redux-store";

type CreateAsyncThunk = typeof createAppAsyncThunk;

// Wraps redux's createAsyncThink in a version that logs errors
export const createErrorReportingAsyncThunk = ((
  name: any,
  action: any,
  options?: any
) =>
  createAsyncThunk(
    name,
    async (...args) => {
      try {
        return await action(...args);
      } catch (e) {
        const { dispatch } = args[1];
        // const err = formatError(e);
        // reportError({
        //     type: "Async action failed",
        //     args: args.length && args[0],
        //     actionType: name,
        //     err,
        // });
        if (!options?.skipNotification) {
          dispatch(
            notificationActions.notify({
              level: "error",
              message: "An unexpected error has occurred",
            })
          );
        }
        throw e;
      }
    },
    options
  )) as any as CreateAsyncThunk;

export const unwrap =
  <T extends (...args: any[]) => any>(asyncThunk: T) =>
  (...args: Parameters<T>) =>
  (dispatch: AppDispatch): Promise<void> =>
    dispatch(asyncThunk(...args)).unwrap();
