import { createBrowserRouter, Navigate, Outlet, Link } from "react-router-dom";
import useFeature from "../ducks/features/hooks";
import Root from "./Root";
import { useAppDispatch, useAppSelector } from "../redux-store";
import { selectAuthState } from "../ducks/auth/selectors";
import { ReactNode, useEffect } from "react";
import { initialize } from "../ducks/auth";
import "./logged-in-layout.css";
import AddIcon from "@mui/icons-material/Add";
import SettingsIcon from "@mui/icons-material/Settings";
import ChecklistRtlIcon from "@mui/icons-material/ChecklistRtl";
import UploadFileIcon from "@mui/icons-material/UploadFile";

// Pages
import IndexPage from "./IndexPage/Loader";
import CreateEditOrderPage from "./CreateEditOrderPage/Loader";
import SettingsPage from "./SettingsPage/SettingsPage";
import Login from "./account/Login/Loader";
import ActivateAccount from "./account/ActivateAccount/Loader";
import UploadCsvPage from "./UploadCsvPage/UploadCsvPage";

const RequireAuth = ({ children }: { children: ReactNode }) => {
  const authState = useAppSelector(selectAuthState);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(initialize());
  }, [dispatch]);
  switch (authState) {
    case "AUTH_ERROR":
      return <>Login fejl :(</>;
    case "UNINITIALIZED":
      return null;
    case "AUTHENTICATED":
      return <>{children}</>;
    case "UNAUTHENTICATED":
      return <Navigate to="/account/login" />;
    default:
      return null;
  }
};

export const routes = {
  index: "/",
  newOrder: "/orders/new",
  uploadOrderCsv: "/upload-order-csv",
  settings: "/settings",
  login: "/account/login",
  activateAccount: "/account/activate",
};

export const Menu = ({ onItemSelected }: { onItemSelected?: Function }) => {
  const orderCsvEnabled = useFeature("webbooking-order-csv-upload");
  return (
    <ul className="menu">
      <li className="menu__item" aria-label="Ordreroverblik">
        <Link
          className="menu__link"
          title={"Ordreroverblik"}
          to="/"
          onClick={() => onItemSelected && onItemSelected()}
        >
          <ChecklistRtlIcon aria-label="Ordreroverblik" fontSize="medium" />
        </Link>
      </li>
      <li className="menu__item" aria-label="Opret ny ordre">
        <Link
          className="menu__link"
          title={"Opret ny ordre"}
          to={routes.newOrder}
          onClick={() => onItemSelected && onItemSelected()}
        >
          <AddIcon aria-label="Opret ny ordre" fontSize="medium" />
        </Link>
      </li>
      {orderCsvEnabled && (
        <li className="menu__item" aria-label="Opret ny ordre">
          <Link
            className="menu__link"
            title={"Upload CSV"}
            to={routes.uploadOrderCsv}
            onClick={() => onItemSelected && onItemSelected()}
          >
            <UploadFileIcon aria-label="Upload CSV" fontSize="medium" />
          </Link>
        </li>
      )}
      <li className="menu__item" aria-label="Settings">
        <Link
          className="menu__link"
          title={"Settings"}
          to={routes.settings}
          onClick={() => onItemSelected && onItemSelected()}
        >
          <SettingsIcon aria-label="Settings" fontSize="medium" />
        </Link>
      </li>
    </ul>
  );
};

const createRouter = () =>
  createBrowserRouter([
    {
      path: routes.index,
      element: <Root />,
      children: [
        {
          path: routes.login,
          element: <Login />,
        },
        {
          path: routes.activateAccount,
          element: <ActivateAccount />,
        },
        {
          path: "/",
          element: (
            <RequireAuth>
              <div className="logged-in-layout">
                <div className="menu-wrapper">
                  <Menu />
                </div>
                <Outlet />
              </div>
            </RequireAuth>
          ),
          children: [
            { index: true, element: <IndexPage /> },
            { path: routes.newOrder, element: <CreateEditOrderPage /> },
            { path: routes.uploadOrderCsv, element: <UploadCsvPage /> },
            { path: routes.settings, element: <SettingsPage /> },
          ],
        },
      ],
    },
  ]);

export default createRouter;
