import { CargoType } from "dora-contracts";
import { createOrderViews, createOrderWithDetails } from "dora-contracts";
import * as t from "io-ts";

export type { CargoType };

export const { placeT, orderViewT, orderViewsT } = createOrderViews({
  dateTime: t.string,
});
export const { orderWithDetailsT, stopT } = createOrderWithDetails({
  decimal: t.string,
  isoDateTime: t.string,
  date: t.string,
});

export type OrderViewPlace = t.TypeOf<typeof placeT>;
export type OrderView = t.TypeOf<typeof orderViewT>;
export type Order = t.TypeOf<typeof orderWithDetailsT>;
export type Stop = t.TypeOf<typeof stopT>;
