import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { nanoid } from "nanoid";
import { AppThunkAction } from "../../../redux-store";

const prefix = "app/notifications";

type Level = "error";

type Notification = {
  id: string;
  level?: Level;
  message: string;
};

const initialState: Notification[] = [];

export const notify =
  (input: { message: string; level?: Level }): any =>
  (dispatch: any) => {
    const id = nanoid();
    dispatch(
      slice.actions.addNotification({
        ...input,
        id,
      })
    );
    setTimeout(() => {
      dispatch(slice.actions.dismissNotification(id));
    }, 5000);
  };

const slice = createSlice({
  initialState,
  name: prefix,
  reducers: {
    addNotification(state, action: PayloadAction<Notification>) {
      state.push(action.payload);
    },
    dismissNotification(state, action: PayloadAction<string>) {
      return state.filter((x) => x.id !== action.payload);
    },
  },
});

export const { dismissNotification } = slice.actions;
export default slice.reducer;
