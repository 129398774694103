import {
  OrderWizardStop,
  OrderWizardStopRedux,
  orderStopToReduxCompatible,
} from "./OrderWizardStop";
import { getBusinessDayFromNowDate } from "./get-business-day-from-now";

export const createEmptyStop = (options?: {
  addExtraDayForDate: boolean;
}): OrderWizardStop => ({
  lm: "",
  weight: "",
  cubicMeters: "",
  colli: "",
  length: "",
  width: "",
  height: "",
  time: "",
  place: null,
  date: getBusinessDayFromNowDate(options && { addExtraDay: true }),
  ref: "",
  contact: "",
  phone: "",
  temperature: "",
  fix: false,
  cargoPieces: [],
  tag: "",

  tagManuallyModified: false,
});

export const createEmptyStopRedux = (options?: {
  addExtraDayForDate: boolean;
}): OrderWizardStopRedux => {
  const emptyStop = createEmptyStop(options);
  return orderStopToReduxCompatible(emptyStop);
};
