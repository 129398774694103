import { createAction, createSlice } from "@reduxjs/toolkit";
import Decimal from "decimal.js-light";
import { OrderWizardPage1Base } from "../../../routes/CreateEditOrderPage/Step1/CreateEditOrderStep1";
import { OrderWizardStopRedux } from "../../../routes/CreateEditOrderPage/stops/OrderWizardStop";
import { createEmptyStopRedux } from "../../../routes/CreateEditOrderPage/stops/create-empty-stop";
import {
  FileWrapper,
  OrderWizardPage4Data,
} from "../../../routes/CreateEditOrderPage/Step4/CreateEditOrderStep4";
import { getBusinessDayFromNowDate } from "../../../routes/CreateEditOrderPage/stops/get-business-day-from-now";
import { Order, orderWithDetailsT, Stop } from "../../data/orders/types";
import { createErrorReportingAsyncThunk, unwrap } from "../../helpers";
import * as tPromise from "io-ts-promise";
import { OrderWizardCargoPieceRedux } from "../../../routes/CreateEditOrderPage/shared/cargo-pieces";
import {
  convertDecimalToString,
  convertStringToDecimal,
} from "../../../helpers/parse-decimal";

const prefix = "app/order-form";

const addUnitDimensions = (
  a: OrderWizardCargoPieceRedux[],
  b: OrderWizardCargoPieceRedux[]
): OrderWizardCargoPieceRedux[] => {
  return a.reduce((prev, curr) => {
    const index = prev.findIndex((x) => x.unit === curr.unit);
    if (index === -1) {
      return [...prev, curr];
    } else {
      const clone = [...prev];
      clone[index] = {
        ...clone[index],
        quantity: clone[index].quantity + curr.quantity,
      };
      return clone;
    }
  }, b);
};

type OrderWizardPage1DataRedux = OrderWizardPage1Base & {
  pickupDate: string;
  dropoffDate: string;
  cargoPieces: OrderWizardCargoPieceRedux[];
};

type OrderWizardPage2DataRedux = {
  pickupPoints: OrderWizardStopRedux[];
};

type OrderWizardPage3DataRedux = {
  dropoffPoints: OrderWizardStopRedux[];
};

type State = {
  step1: {
    cargoType?: string | null;
    description?: string;
    quantity?: string;
    unitId?: string;
    invoiceRef?: string;
    loadMeter?: string;
    temperature?: string;
    fix?: boolean;
    colli?: string;
    weight?: string;
    cubicMeters?: string;
    height?: string;
    width?: string;
    length?: string;
    pickupDate: string;
    dropoffDate: string;
    minTemperature: string;
    maxTemperature: string;
    note: string;
    cargoPieces: OrderWizardCargoPieceRedux[];
  };
  step2: {
    pickupPoints: OrderWizardStopRedux[];
  };
  step3: {
    dropoffPoints: OrderWizardStopRedux[];
  };
  step4: {
    files: FileWrapper[];
  };
  skipDropoffs: boolean;
};

export const orderFormInitialState: State = {
  step1: {
    cargoType: "",
    description: "",
    quantity: "",
    unitId: "",
    invoiceRef: "",
    loadMeter: "",
    colli: "",
    weight: "",
    cubicMeters: "",
    height: "",
    width: "",
    length: "",
    temperature: "",
    minTemperature: "",
    maxTemperature: "",
    fix: false,
    note: "",
    pickupDate: getBusinessDayFromNowDate().toISOString(),
    dropoffDate: getBusinessDayFromNowDate({
      addExtraDay: true,
    }).toISOString(),
    cargoPieces: [],
  },
  step2: {
    pickupPoints: [createEmptyStopRedux()],
  },
  step3: {
    dropoffPoints: [createEmptyStopRedux({ addExtraDayForDate: true })],
  },
  step4: {
    files: [],
  },
  skipDropoffs: false,
};

export const orderWizardPage1Submitted =
  createAction<OrderWizardPage1DataRedux>(
    `${prefix}/order-wizard-page-1-data-submitted`
  );

export const orderWizardPage2Submitted =
  createAction<OrderWizardPage2DataRedux>(
    `${prefix}/order-wizard-page-2-data-submitted`
  );

export const orderWizardPage3Submitted =
  createAction<OrderWizardPage3DataRedux>(
    `${prefix}/order-wizard-page-3-data-submitted`
  );

export const setSkipDropoff = createAction<boolean>(
  `${prefix}/set-skip-dropoff`
);

export const orderWizardPage4Submitted = createAction<OrderWizardPage4Data>(
  `${prefix}/order-wizard-page-4-data-submitted`
);

export const clearOrderFormData = createAction(
  `${prefix}/clear-order-form-data`
);

const setOrderFormData = createAction<Order>(`${prefix}/set-order-form-data`);

const doGetOrderForFormData = createErrorReportingAsyncThunk(
  `${prefix}/get-order-update-form-data`,
  async ({ id }: { id: string }, { dispatch }) => {
    const result = await fetch(`/web-booking-api/orders/${id}`);
    if (!result.ok) {
      throw new Error("Error loading data");
    }
    const data = await result.json().then(tPromise.decode(orderWithDetailsT));
    return dispatch(setOrderFormData(data));
  }
);

export const getOrderForFormData = unwrap(doGetOrderForFormData);

function copyPrefilledData(
  data: OrderWizardPage1DataRedux,
  stop: OrderWizardStopRedux
) {
  stop.lm = data.loadMeter;
  stop.length = data.length;
  stop.height = data.height;
  stop.width = data.width;
  stop.weight = data.weight;
  stop.cubicMeters = data.cubicMeters;
  stop.colli = data.colli;
  stop.ref = data.invoiceRef;
  stop.temperature = data.temperature;
  stop.fix = data.fix;
  stop.cargoPieces = data.cargoPieces;
}

const mapStopForCopiedOrderData = (p: Stop) => ({
  lm: p.lm || "",
  date: getBusinessDayFromNowDate().toISOString(),
  time: p.time || "",
  weight: p.weight || "",
  cubicMeters: p.cubicMeters || "",
  height: p.height || "",
  width: p.width || "",
  length: p.length || "",
  colli: p.colli || "",
  ref: p.ref || "",
  contact: p.contact || "",
  phone: p.phone || "",
  fix: !!p.fix,
  temperature: p.temperature || "",
  place: p.place,
  cargoPieces: p.cargoPieces,
  tag: p.tag || "",
  tagManuallyModified: false,
});

const slice = createSlice({
  initialState: orderFormInitialState,
  name: prefix,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(orderWizardPage1Submitted, (state, action) => {
        const data = {
          ...action.payload,
          cargoPieces: action.payload.cargoPieces.map((x) => {
            if (!x.unit) {
              throw new Error("Bad validation");
            }
            return {
              unit: x.unit,
              quantity: +x.quantity,
            };
          }),
        };
        state.step1 = data;

        const pickupPoints = state.step2.pickupPoints;
        const dropPoints = state.step3.dropoffPoints;

        const useDimensionsFromPage1 =
          pickupPoints.length === 1 && dropPoints.length === 1;

        if (useDimensionsFromPage1) {
          const pickup = pickupPoints[0];
          const dropoff = dropPoints[0];

          pickup.date = data.pickupDate;
          dropoff.date = data.dropoffDate;

          copyPrefilledData(data, pickup);
          copyPrefilledData(data, dropoff);
        }

        // if (pickupPoints.length === 1 && !pickupPoints[0].place) {
        //   const pickup = pickupPoints[0];
        //   pickup.date = pickup.date || data.pickupDate;
        //   pickup.lm = data.loadMeter;
        //   copyPrefilledData(data, pickup);
        // }
        // if (dropPoints.length === 1 && !dropPoints[0].place) {
        //   const dropoff = dropPoints[0];
        //   dropoff.date = dropoff.date || data.dropoffDate;
        //
        //   copyPrefilledData(data, dropoff);
        // }
      })
      .addCase(orderWizardPage2Submitted, (state, action) => {
        const data = action.payload;
        state.step2 = action.payload;

        const dropPoints = state.step3.dropoffPoints;
        const pickupPoints = data.pickupPoints;

        const cmrTags = pickupPoints
          .map((p) => p.tag)
          .filter((t) => t)
          .join("/");
        for (const d of dropPoints) {
          if (!d.tagManuallyModified) {
            d.tag = cmrTags;
          }
        }

        if (dropPoints.length === 1 && !dropPoints[0].place) {
          const dropoff = dropPoints[0];
          dropoff.lm = convertDecimalToString(
            pickupPoints.reduce((acc, p) => {
              return acc.add(convertStringToDecimal(p.lm));
            }, new Decimal(0))
          );
          dropoff.cargoPieces = pickupPoints.reduce((acc, p) => {
            return addUnitDimensions(acc, p.cargoPieces);
          }, [] as OrderWizardCargoPieceRedux[]);
        }
      })
      .addCase(orderWizardPage3Submitted, (state, action) => {
        state.step3 = action.payload;
      })
      .addCase(orderWizardPage4Submitted, (state, action) => {
        state.step4 = action.payload;
      })
      .addCase(clearOrderFormData, (state) => {
        state.step1 = orderFormInitialState.step1;
        state.step2 = orderFormInitialState.step2;
        state.step3 = orderFormInitialState.step3;
        state.step4 = orderFormInitialState.step4;
      })
      .addCase(setOrderFormData, (state, action) => {
        const order = action.payload;
        const pickups = order.pickups;

        state.step1 = {
          cargoType: order.cargoType,
          description: order.description,
          quantity: order.quantity || "",
          unitId: order.unitId || "",
          note: order.note || "",
          invoiceRef: pickups[0].ref || "",
          loadMeter: convertDecimalToString(
            pickups.reduce((a, p) => {
              return a.add(new Decimal(p.lm));
            }, new Decimal(0))
          ),
          pickupDate: getBusinessDayFromNowDate().toISOString(),
          dropoffDate: getBusinessDayFromNowDate({
            addExtraDay: true,
          }).toISOString(),
          minTemperature: order.minTemperature || "",
          maxTemperature: order.maxTemperature || "",
          temperature: pickups[0].temperature || "",
          fix: !!pickups[0].fix,
          colli: pickups[0].colli || "",
          weight: pickups[0].weight || "",
          height: pickups[0].height || "",
          width: pickups[0].width || "",
          length: pickups[0].length || "",
          cargoPieces: [],
        };

        state.step2 = { pickupPoints: pickups.map(mapStopForCopiedOrderData) };

        state.step3 = {
          dropoffPoints: order.dropoffs.map(mapStopForCopiedOrderData),
        };
      })
      .addCase(setSkipDropoff, (state, action) => {
        state.skipDropoffs = action.payload;
      });
  },
});

export default slice.reducer;
