import * as React from "react";
import classnames from "classnames";
import "./Button.scss";

type HTMLButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>;

type ButtonVariant = "primary" | "danger" | "danger-bordered" | "ghost";

type ButtonProps = {
  variant: ButtonVariant;
} & HTMLButtonProps;

const Button = (props: ButtonProps) => {
  const { variant, className, ...rest } = props;
  const buttonClass = classnames("button-base", className, {
    "button-primary": variant == "primary",
    "button-danger": variant == "danger",
    "button-danger--bordered": variant == "danger-bordered",
    "button-ghost": variant == "ghost",
  });

  return <button className={buttonClass} {...rest} />;
};

type IconButtonProps = HTMLButtonProps & {
  variant: "danger";
};

export const IconButton = (props: Partial<IconButtonProps>) => {
  const { className, variant, ...rest } = props;
  return (
    <button
      className={classnames(
        "icon-button",
        className,
        variant && `icon-button--${variant}`
      )}
      {...rest}
    />
  );
};

export default Button;
