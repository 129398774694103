import { combineReducers } from "redux";
import notifications from "./notifications";
import orderForm from "./order-form";
import userSettings from "./user-settings";
import googleNotifications from "./google-notifications";
import apiKeys from "./api-keys";

export default combineReducers({
  notifications,
  orderForm,
  userSettings,
  googleNotifications,
  apiKeys
});
