// borrowed from https://www.codemzy.com/blog/fix-chunkloaderror-react

// a function to retry loading a chunk to avoid chunk load error for out of date code
export const lazyRetry = function <T>(
  componentImport: () => Promise<T>,
  name: string
) {
  return new Promise<T>((resolve, reject) => {
    const key = `retry-${name}-refreshed`;
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem(key) || "false"
    );
    // try to import the component
    componentImport()
      .then((component) => {
        window.sessionStorage.setItem(key, "false"); // success so reset the refresh
        resolve(component);
      })
      .catch((error) => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem(key, "true"); // we are now going to refresh
          return window.location.reload(); // refresh the page
        }
        reject(error); // Default error behaviour as already tried refresh
      });
  });
};
