import { combineReducers } from "redux";
import org from "./org";
import auth from "./auth";
import app from "./app";
import data from "./data";
import features from "./features";

const rootReducer = combineReducers({ org, auth, app, data, features });

export default rootReducer;
