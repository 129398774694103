import WidePageLayout from "../../components/layout/WidePageLayout";
import Dropzone from "react-dropzone";
import Button from "../../components/Button";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "../createRouter";
import FileUploadContainer from "../../components/FileUploadContainer";
import { useAppDispatch } from "../../redux-store";
import { notify } from "../../ducks/app/notifications";
import "./UploadCsvPage.scss";
import { unitsOptions } from "../CreateEditOrderPage/shared/units";
import { cargoTypes } from "dora-contracts";
import { getUnits } from "dora-shared";

const UploadCsvPage = () => {
  const [uploadErrors, setUploadErrors] = useState<any>(null);
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onFilesAdded = (acceptedFiles: File[]) => {
    setFile(acceptedFiles[0]);
  };

  const onUploadButtonClick = async () => {
    setUploadErrors(null);
    setUploadInProgress(true);
    const formData = new FormData();
    const f = file as File;
    formData.append("file", new Blob([f], { type: f.type }), f.name);
    try {
      const response = await fetch("/web-booking-api/orders/upload-csv", {
        method: "POST",
        body: formData,
      });
      if (!response.ok) {
        dispatch(
          notify({
            level: "error",
            message: "Upload failed!",
          })
        );
        if (response.status === 400) {
          const errors = await response.json();
          setUploadErrors(errors);
        } else {
          setUploadErrors(["Server error"]);
        }
        setUploadInProgress(false);
      } else {
        dispatch(
          notify({
            message: "Successfully created orders from csv file!",
          })
        );
        navigate(routes.index);
      }
    } catch (err) {}
  };

  return (
    <WidePageLayout header={"Create order from CSV"}>
      <div className="upload-csv__container">
        <Dropzone
          maxSize={50000000}
          accept={{
            "file/csv": [".csv"],
          }}
          maxFiles={1}
          onDrop={onFilesAdded}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <FileUploadContainer
                text1={"Drag & drop CSV file here"}
                text2={"(only .csv format)"}
              />
            </div>
          )}
        </Dropzone>
        {file && <div className="mt-4">File to be uploaded: {file.name}</div>}
        {uploadErrors && (
          <div className="upload-csv__error">
            <div>Upload error:</div>
            <br />
            {uploadErrors.errors.map((e: any, i: number) => (
              <div key={i}>
                <FormatError error={e} />
              </div>
            ))}
            {uploadErrors.reqId && (
              <p>
                Ved henvendelse til DORA, venligst angiv følgende{" "}
                <code>reqId</code>: {uploadErrors.reqId}
              </p>
            )}
          </div>
        )}
        <div className="flex justify-end mt-4">
          <Button
            disabled={uploadInProgress || !file}
            variant={"primary"}
            onClick={onUploadButtonClick}
          >
            Upload
          </Button>
        </div>
      </div>
    </WidePageLayout>
  );
};

const FormatError = ({ error }: { error: any }) => {
  switch (error.type) {
    case "ERR_MISSING_FILE":
      return <>Der mangler en fil</>; // Should never happen
    case "ERR_NO_ROWS":
      return (
        <>Der er ingen rækker i filen (eller også manger den overskrifter)</>
      );
    case "ERR_INVALID_FILE":
      return <>"Filen kunn ikke fortolkes som en gyldig CSV file"</>; // Should never happen
    case "ERR_TOO_MANY_ROWS":
      return (
        <>
          "Der er flere ordrer i samme fil. Systemet kan kun håndtere én ordre
          ad gangen"
        </>
      );
    case "ERR_COLUMN_ERROR":
      return (
        <>
          <h3>
            Fejl i felt: <code>{error.column}</code>
          </h3>
          <ColumnError error={error} />
        </>
      );
    default:
      return (
        <>
          "En uventet fejl er opstået under CSV import, kontakt venligst DORA"
        </>
      );
  }
};

const ColumnError = ({ error }: { error: any }) => {
  const column: string = error.column;
  if (column.startsWith("unit ") || column.startsWith("quantity ")) {
    return (
      <>
        <p>
          <code>unit</code> og <code>quantity</code> felter skal passe sammen.
          Er der fx. et <code>unit 1</code>, skal der også være{" "}
          <code>quantity 1</code>
        </p>
        <p>
          <code>quantity</code> skal være et helt tal, og <code>unit</code> skal
          være en af følgende
        </p>
        <ul>
          {getUnits().map((unit) => (
            <li key={unit.id}>{unit.id}</li>
          ))}
        </ul>
      </>
    );
  }
  switch (error.column) {
    case "cargo type":
      return (
        <>
          <p>Godstypen skal være en af følgende</p>
          <ul>
            {cargoTypes.map((type) => (
              <li key={type}>{type}</li>
            ))}
          </ul>
        </>
      );
    default:
      return null;
  }
};

export default UploadCsvPage;
