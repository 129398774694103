import { createSlice } from "@reduxjs/toolkit";

const prefix = "app/google-notifications";

type State = {
  showError: boolean;
};

const initialState: State = {
  showError: false,
};

const slice = createSlice({
  initialState,
  name: prefix,
  reducers: {
    errorOccurred: () => ({ showError: true }),
    errorCleared: () => ({ showError: false }),
    userNavigatedAway: () => ({ showError: false }),
  },
});

export default slice.reducer;
export const { errorOccurred, errorCleared, userNavigatedAway } = slice.actions;
