import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as t from "io-ts";
import axios from "axios";
import * as tPromise from "io-ts-promise";

const prefix = "app/load-api-keys";

type State = {
  googleMapsApiKey: string;
};

const initialState: State = {
  googleMapsApiKey: "",
};

const apiKeys = t.strict({
  googleMapsApiKey: t.string,
});

export const loadApiKeys = createAsyncThunk(prefix, async () => {
  return axios
    .get(`/api/api-keys`)
    .then((res) => res.data)
    .then(tPromise.decode(apiKeys));
});

const apiKeysSlice = createSlice({
  name: prefix,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadApiKeys.fulfilled, (state, action) => {
      state.googleMapsApiKey = action.payload.googleMapsApiKey;
    });
  },
});

export default apiKeysSlice.reducer;
