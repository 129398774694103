import { ReactNode } from "react";
import "./WidePageLayout.css";

const WidePageLayout = ({
  header,
  children,
}: {
  header: ReactNode;
  children: ReactNode;
}) => (
  <div className="wide-layout">
    <div className="wide-layout__box">
      <h1 className="wide-layout__heading">{header}</h1>
      {children}
    </div>
  </div>
);

export default WidePageLayout;
