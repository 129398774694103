import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const prefix = "org";

type State = {
  org?: { name: string };
};

const initialState: State = {};

export const initialize = createAsyncThunk(`${prefix}/initialize`, async () => {
  const result = await fetch("/web-booking-api/tenant");
  return result.json();
});

const slice = createSlice({
  initialState,
  name: prefix,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(initialize.fulfilled, (state, action) => {
      state.org = action.payload;
    });
  },
});

export default slice.reducer;
