import { Decimal } from "decimal.js-light";

export const convertStringToDecimal = (decimalString: string) => {
  // Replaces just the first occurrence of ',', by converting it to $ as a placeholder and then omitting all other decimal seperators
  const str = decimalString
    .replace(",", "$")
    .replaceAll(",", "")
    .replaceAll(".", "")
    .replace("$", ".");
  return new Decimal(str);
};

export const convertDecimalToString = (decimalInput: Decimal) => {
  return decimalInput.toNumber().toLocaleString("da-DK");
};
