import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import englishFlag from "../../assets/flags/en.png";
import danishFlag from "../../assets/flags/da.png";
import "./SettingsPage.scss";
import WidePageLayout from "../../components/layout/WidePageLayout";
import { useAppDispatch, useAppSelector } from "../../redux-store";
import { selectCurrentLanguage } from "../../ducks/app/user-settings/selectors";
import { setLanguage } from "../../ducks/app/user-settings";

const SettingsPage = () => {
  const language = useAppSelector(selectCurrentLanguage);
  const dispatch = useAppDispatch();

  const onLanguageChange = (e: any) => {
    dispatch(setLanguage(e.target.value));
  };

  return (
    <WidePageLayout header={"Settings"}>
      <div className="settings-page">
        <div className="mt-3">
          <label id="language-selector__label" htmlFor="language-selector">
            Language
          </label>
          <div className="language-selector__content">
            <FormControl fullWidth>
              <Select
                value={language}
                id="language-selector__select"
                labelId="language-selector__label"
                onChange={(e) => onLanguageChange(e)}
              >
                <MenuItem key="en" value="en">
                  <div className="langauge-selector__option">
                    <img src={englishFlag} alt="English" />
                    <div>English</div>
                  </div>
                </MenuItem>
                <MenuItem key="da" value="da">
                  <div className="langauge-selector__option">
                    <img src={danishFlag} alt="Danish" />
                    <div>Danish</div>
                  </div>
                </MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
      </div>
    </WidePageLayout>
  );
};

export default SettingsPage;
